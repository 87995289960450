// author: Victor K.
import { FC, useState } from 'react';
import { MenuLink } from '../../components';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {};

const MenuLinks = [
  {
    title: 'О конкурсе',
    linkTo: '',
  },{
    title: 'Заявка',
    linkTo: '',
  },{
    title: 'Экспертное жюри',
    linkTo: '',
  },{
    title: 'Партнеры',
    linkTo: '',
  },{
    title: 'Контакты',
    linkTo: '',
  },
];

const Menu: FC<Props> = () => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const menuHandler = (id: number) => {
    const items = Array.from(document.getElementsByClassName('linkedTitle'));
    items?.forEach(item => {
      if (item.innerHTML === MenuLinks[id].linkTo || item.innerHTML === MenuLinks[id].title) {
        const top = item.getBoundingClientRect().y;
        console.log(top);
        if (top > 0) {
          window.scrollTo({ top: top-30, behavior: 'smooth' });
          // window.scrollTo({ top: top-90, behavior: 'smooth' });
        } else {
          // window.scrollBy({ top: top-90, behavior: 'smooth' });
        }
        setOpen(false);
        return;
      }
    });
  }

  return <div className={styles.menuWrap}>
    <div
      className={cx(
        styles.burger,
        {[styles.opened]: isOpen}
      )}
      onClick={() => setOpen(!isOpen)}
    />
    <div className={cx(
      styles.topMenu,
      {[styles.opened]: isOpen}
    )}>
      {MenuLinks.map((link, id) => (
        <MenuLink
          className={styles.topLink}
          key={id}
          title={link.title}
          onClick={() => menuHandler(id)}
        />
      ))}
    </div>
  </div>
}

export default Menu;