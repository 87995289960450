// author: Victor K.
import { TExpert, TExpertCat, TExpertType } from '../types';

export const ExpertData:TExpert[] = [
  {
    id: 0,
    full_name: 'Имя Фамилия Отчество 0',
    type_of_expert: 1,
    category_of_professions: 1,
    position: 'Заместитель председателя Комитета по туризму города Москвы',
    is_active: true,
  },{
    id: 1,
    full_name: 'Имя Фамилия Отчество 1',
    type_of_expert: 1,
    category_of_professions: 2,
    position: 'Заместитель председателя Комитета общественных связей и молодежной политики города Москвы',
    is_active: true,
  },{
    id: 2,
    full_name: 'Имя Фамилия Отчество 2',
    type_of_expert: 1,
    category_of_professions: 1,
    position: 'Заместитель руководителя Департамента культурного наследия города Москвы',
    is_active: true,
  },{
    id: 3,
    full_name: 'Имя Фамилия Отчество 3',
    type_of_expert: 2,
    category_of_professions: 1,
    position: 'Заместитель председателя Комитета по туризму города Москвы',
    is_active: true,
  },{
    id: 4,
    full_name: 'Имя Фамилия Отчество 4',
    type_of_expert: 2,
    category_of_professions: 1,
    position: 'Заместитель председателя Комитета общественных связейи молодежной политики города Москвы',
    is_active: true,
  },{
    id: 5,
    full_name: 'Имя Фамилия Отчество 5',
    type_of_expert: 3,
    category_of_professions: 1,
    position: 'Заместитель руководителя Департамента культурного наследия города Москвы',
    is_active: true,
  },{
    id: 6,
    full_name: 'Имя Фамилия Отчество 6',
    type_of_expert: 3,
    category_of_professions: 1,
    position: 'Заместитель председателя Комитета по туризму города Москвы',
    is_active: true,
  },{
    id: 7,
    full_name: 'Имя Фамилия Отчество 7',
    type_of_expert: 4,
    category_of_professions: 2,
    position: 'Заместитель председателя Комитета общественных связей и молодежной политики города Москвы',
    is_active: true,
  },{
    id: 8,
    full_name: 'Имя Фамилия Отчество 8',
    type_of_expert: 1,
    category_of_professions: 1,
    position: 'Заместитель руководителя Департамента культурного наследия города Москвы',
    is_active: true,
  },{
    id: 9,
    full_name: 'Имя Фамилия Отчество 9',
    type_of_expert: 2,
    category_of_professions: 1,
    position: 'Заместитель председателя Комитета по туризму города Москвы',
    is_active: true,
  },{
    id: 10,
    full_name: 'Имя Фамилия Отчество 10',
    type_of_expert: 2,
    category_of_professions: 2,
    position: 'Заместитель председателя Комитета общественных связейи молодежной политики города Москвы',
    is_active: true,
  },{
    id: 11,
    full_name: 'Имя Фамилия Отчество 11',
    type_of_expert: 1,
    category_of_professions: 1,
    position: 'Заместитель руководителя Департамента культурного наследия города Москвы',
    is_active: true,
  },
];

// Группа должности (параметр объединения в раскрывающемся блоке)
export const ExpertTypeData:TExpertType[] = [
  {
    id: 1,
    title: 'Председатели',
    is_active: true,
  },{
    id: 2,
    title: 'Директора',
    is_active: true,
  },{
    id: 3,
    title: 'Другие ребята',
    is_active: true,
  },{
    id: 4,
    title: 'Еще кто-нибудь',
    is_active: true,
  }
];

// категория эксперта (из свитчера)
export const ExpertCatData:TExpertCat[] = [
  {
    id: 1,
    title: 'Состав организационного комитета по проведению конкурса профессионального мастерства «Московские мастера» по профессям «Гид-переводчик», «Экскурсовод (авторские пешеходные и автобусные экскурсии)» и «Специалист по организации и проставлению туристических услуг»',
    is_active: true,
  },{
    id: 2,
    title: 'Состав организационного комитета по проведению конкурса профессионального мастерства «Московские мастера», по профессии «Администратор гостиницы»',
    is_active: true,
  }
];