// author: Victor K.
import { FC } from 'react';
import {
  BlockWrapper,
  ApplyForm,
} from '../../containers';
import {
  IEventReq,
  TProfessionsReq,
} from '../../types';
import {
  Menu,
} from '../../components';
// import { AppliesData } from '../../_testData';
import styles from './styles.module.scss';

type Props = {
  active?: boolean,
  count?: number,
  Event: IEventReq | null,
  Professions: TProfessionsReq | null,
}

const Header: FC<Props> = ({
  active = true,
  count = 0,
  Event,
  Professions,
}) => (
  <BlockWrapper
    noTitle
    className={styles.headerWrap}
    contentClassName={styles.content}
    childClassName={styles.childs}
  >
    <Menu />
    {active && (<div className={styles.rightCol}>
      {count>0 && <div className={styles.assigns}>
        Подано заявок: <span className={styles.count}>{count}</span>
      </div>}
      <ApplyForm
        openerTitle='Подать заявку'
        Event={Event}
        Professions={Professions}
      />
    </div>)}
  </BlockWrapper>
);

export default Header;
