// author: Victor K.
import { FC, useState } from 'react';
import {
  IFullAppl,
  TStringsObj,
  TNumericObj,
  IEventReq,
  TProfessionsReq,
} from '../../types';
import {
  Form,
  // ExternalFile,
  CheckboxSwitcher,
  InputFile,
} from '../../components';
import { useRequest } from '../../hooks';
import { URL } from '../../appConstants';
import { Placeholders, InputTitle, InputErrors } from './script';
import Translator from './_translator';
import TourGuide from './_tour_guide';
import TourSpec from './_tour_spec';
import HotelAdmin from './_hotel_admin';

import cx from 'classnames';
import { toast } from 'react-toastify';
import styles from './styles.module.scss';

type Props = {
  invert?: boolean,
  accent?: boolean,
  openerTitle?: string,
  Event: IEventReq | null,
  Professions: TProfessionsReq | null,
}

const STEPS = [
  'Выберите профессию',
  'Основная информация',
];

const initPart = {
  full_name: '',
  date_of_birth: '',
  home_address: '',
  phone: '',
  email: '', // max 250
  work_experience: '',
  education: '',
  foreign_languages: '',

  approval_first: false,
  photo: '',
};
const initAddsTranslator = { // ITranslatorAppl
  secret_code: '',
  route_name: '',
  route: '',
  objects_during_route: '',
  duration: '',
  route_km: '',
  short_description: '',
  video_fragment: '',
  video_trailer: '',
};
const initAddsTourGuide = { // ITourGuideAppl
  secret_code: '',
  route_name: '',
  route: '',
  objects_during_route: '',
  duration: '',
  route_km: '',
  short_description: '',
  video_fragment: '',
  video_trailer: '',
};
const initAddsHotelAdmin = { // IHotelAdminAppl
  admin_experience: '',
  work_union: '',
  work_address: '',
  additional_education: '',
  video_trailer: '',
  essay: '',
};
const initAddsTourSpec = { // ITourSpecAppl
  work_address: '',
  amount_of_tours: '',
  presentation_link: '',
  short_description: '',
  tour_program_ru: '',
  tour_program_en: '',
};

const initFullFields = { // IFullAppl
  secret_code: '',
  route_name: '',
  route: '',
  objects_during_route: '',
  duration: '',
  work_union: '',
  route_km: '',
  short_description: '',
  essay: '',
  video_fragment: '',
  video_trailer: '',
  admin_experience: '',
  work_address: '',
  additional_education: '',
  amount_of_tours: '',
  presentation_link: '',
  tour_program_ru: '',
  tour_program_en: '',
};

// объект связи внутренних данных с внешним ID
const PROF_BACK_ID:TNumericObj = {
  3: {
    inner_id: 1,
    title: 'Гид-переводчик',
    component: Translator,
    form_fields: {...initPart, ...initAddsTranslator},
    route: URL.APPLICATION.TRANSLATOR.POST,
  },
  4: {
    inner_id: 2,
    title: 'Экскурсовод',
    component: TourGuide,
    form_fields: {...initPart, ...initAddsTourGuide},
    route: URL.APPLICATION.TOUR_GUIDE.POST,
  },
  2: {
    inner_id: 3,
    title: 'Администратор гостиницы',
    component: HotelAdmin,
    form_fields: {...initPart, ...initAddsHotelAdmin},
    route: URL.APPLICATION.HOTEL_ADMIN.POST,
  },
  5: {
    inner_id: 4,
    title: 'Специалист по организации и предоставлению туристских услуг',
    component: TourSpec,
    form_fields: {...initPart, ...initAddsTourSpec},
    route: URL.APPLICATION.TOUR_SPEC.POST,
  },
}

const ApplyForm: FC<Props> = ({
  invert = false,
  accent = false,
  openerTitle = 'Открыть форму',
  Event,
  Professions,
}) => {
  const initValues = {
    ...initPart,
    ...initFullFields,
  }

  const [step, setStep] = useState<0|1>(0);
  const [profBackId, setProfBackId] = useState<number>(0);
  const [autoClose, setAutoClose] = useState<boolean>(false);
  const [photo, setPhoto] = useState<File>();
  const [formErrors, setFormErrors] = useState<TStringsObj>({});
  
  const [formData, setFormData] = useState<IFullAppl>(initValues);
  const { request: sendData } = useRequest<IFullAppl>({
    callback: () => {
      setFormData(initValues);
      setAutoClose(true);
      toast.success(`Форма успешно отправлена!`);
    },
    errback: () => {
      toast.error("Ошибка отправки формы! Пожалуйста, повторите позже.");
    }
  });
  
  const nextButtonHandler = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (step === 0) {
      if (profBackId) {
        setStep(1);
      }
    } else {
      let errors = 0;
      for (const key in formData) {
        if (key !== 'profession' &&
          key !== 'work_union' &&
          /* @ts-ignore */
          validateHandler(key, formData?.[key])
        ) { ++errors }
      }
      if (!errors) { submitHandler(); }
    }
  }
  
  const submitHandler = () => {
    const formatDate = () => {
      const date = new Date(formData.date_of_birth);
      const M = `0${date.getMonth()+1}`;
      const D = `0${date.getDate()}`;
      return `${date.getFullYear()}-${M.substring(M.length-2)}-${D.substring(D.length-2)}`;
    }
    const Data = new FormData();
    /* @ts-ignore */
    for (const key in formData) { Data.append(key, `${formData[key]}`) }
    Data.append('event', `${Event?.results[0].id}`);
    Data.append('profession', `${profBackId}`);
    /* @ts-ignore */
    Data.set('photo', photo);
    Data.set('date_of_birth', formatDate());

    const targetRoute = () => {
      if (profBackId) return PROF_BACK_ID[profBackId].route;
      return 'non-specified';
    };
    sendData({
      method: 'POST',
      url: targetRoute(),
      data: Data,
    });
  }

  const onCloseHandler = () => {
    setStep(0);
    setProfBackId(0);
    setFormErrors({});
  };

  const onOpenHandler = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setStep(0);
    setAutoClose(false);
  };

  const validateHandler = (name: string, value: boolean | string | object) => {
    let isError = false;
    const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,4})$/;
    if (
      !value ||
      (name === 'phone' && (value as string).length < 12) ||
      (name === 'email' && !reg.test(value as string))
    ) {
      isError = true;
      setFormErrors(prevState => ({
        ...prevState,
        [name]: InputErrors[name] || InputErrors.default,
      }))
    } else {
      setFormErrors(prevState => {
        if (name in prevState) delete prevState[name];
        return {...prevState}
      })
    }
    return isError;
  }

  const profHandler = (profBackId: number) => {
    // console.log(PROF_BACK_ID[profBackId]);
    setProfBackId(profBackId);
    setFormData({
      ...PROF_BACK_ID[profBackId].form_fields,
    });
  }

  const changeHandler = (e:any) => {
    const { name, value } = e.target;
    validateHandler(name, value);
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const changePhotoHandler = (e:any) => {
    const { file, name } = e;
    setPhoto(file);
    validateHandler(name, file.name);
    setFormData(prevState => ({
      ...prevState,
      [name]: file.name,
    }))
  }

  const radioHandler = (name: string, val: boolean) => {
    validateHandler(name, val);
    setFormData(prevState => ({
      ...prevState,
      [name]: val,
    }))
  }

  return (
    <Form
      invert={invert}
      accent={accent}
      withArrow={!step}
      autoClose={autoClose}
      openerTitle={openerTitle}
      title="Заявка на участие в конкурсе"
      submitTitle={!step ? 'Далее' : 'Отправить заявку'}
      onSubmit={nextButtonHandler}
      onOpen={onOpenHandler}
      onClose={onCloseHandler}
    >
      <h3 className={styles.subtitle}>{`Шаг ${step+1}. ${STEPS[step]}`}</h3>

      {step === 0 && (
        <div className={styles.profGrid}>
          {Professions?.results?.filter(item => item.is_active).map((item, id) => 
            <div
              key={`prof_${id}`}
              className={cx(
                styles.profItem,
                styles[`item_${PROF_BACK_ID[item.id].inner_id}`],
                {[styles.selected]: profBackId === (item.id)}
              )}
              onClick={() => profHandler(item.id)}
              >
              {item.title}
            </div>
          )}
        </div>
      )}

      {step === 1 && (<>
        {PROF_BACK_ID[profBackId].component({
          onChange: changeHandler,
          formData: formData,
          formErrors: formErrors,
        })}

        <InputFile
          name='photo'
          value={formData.photo}
          title={InputTitle.photo}
          onChange={changePhotoHandler}
          placeholder={Placeholders.photo}
          error={formErrors?.photo}
        />

        <CheckboxSwitcher
          name='approval_first'
          value={formData.approval_first}
          text={InputTitle.approval_first}
          onClick={radioHandler}
          error={formErrors?.approval_first}
        />

        {/* <ExternalDocs
          className={styles.link}
        /> */}
      </>)}
    </Form>
  )
}

export default ApplyForm;