// author: Victor K.
import { FC } from 'react';
import { BlockWrapper } from '../../containers';
// import { HTMLContent } from '../../components';
import { TEvent } from '../../types';
// import { TimetableData } from '../../_testData';
import styles from './styles.module.scss';

type Props = {
  data?: TEvent,
}

const refactorDate = (str:any) => {
  return `${str?.split('-')[2]}.${str?.split('-')[1]}`
}

const TimetableBlock: FC<Props> = ({
  data,
}) => {
  const DATES = [
    {
      range: `${refactorDate(data?.start_appl)} - ${refactorDate(data?.end_appl)}`,
      name: 'Приём заявок',
    },{
      range: `${refactorDate(data?.start_evalatuion)} - ${refactorDate(data?.end_evalatuion)}`,
      name: 'Оценка работ организационным комитетом',
    },{
      range: `${refactorDate(data?.start_f2f_contests)} - ${refactorDate(data?.end_f2f_contests)}`,
      name: 'Очные конкурсы',
    },{
      range: `${refactorDate(data?.start_results)} - ${refactorDate(data?.end_results)}`,
      name: 'Подведение итогов конкурса',
    }
  ];

  return (
    <BlockWrapper
      className={styles.timetableWrap}
      contentClassName={styles.content}
      childClassName={styles.childs}
      noTitle
    >
      <h3>График проведения конкурса</h3>
      <div className={styles.timeLine}>
        {DATES.map((date, id) => 
          <div
            key={`time_${id}`}
            className={styles.timeItem}
          >
            <div className={styles.itemRange}>{date.range}</div>
            {date.name}
          </div>
        )}
      </div>
      {/* <HTMLContent
        content={data?.schedule || ''}
      /> */}
    </BlockWrapper>
  )
}

export default TimetableBlock;