// author: Victor K.
import { FC, useEffect, useState } from 'react';
import {
  TFinals,
  TNumericObj,
  TProfession,
} from '../../types';
import { useRequest } from '../../hooks';
import { URL } from '../../appConstants';
import { BlockWrapper } from '../';
import { ExpertItem, HTMLContent } from '../../components';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  professions?: TProfession[],
  HAData?: TFinals[],
  TGData?: TFinals[],
  TSAData?: TFinals[],
  TRData?: TFinals[],
}



const FinalsBlock: FC<Props> = ({
  professions,
  HAData,
  TGData,
  TSAData,
  TRData,
}) => {
  const FiltWinners = (arr: TFinals[]) => arr.filter(item => item.winner);
  const [profBackId, setProfBackId] = useState<number>(0);

  if (HAData) console.log('Отели', FiltWinners(HAData))
  if (TGData) console.log('Гиды', FiltWinners(TGData))
  if (TSAData) console.log('Туризм', FiltWinners(TSAData))
  if (TRData) console.log('Переводчики', FiltWinners(TRData))
  
  const MAIN_PROF_BACK_ID:TNumericObj = {
    3: {
      inner_id: 1,
      title: 'Гид-переводчик',
      data: TRData,
    },
    2: {
      inner_id: 3,
      title: 'Администратор гостиницы',
      data: HAData,
    },
  }

  const ADD_PROF_BACK_ID:TNumericObj = {
    4: {
      inner_id: 2,
      title: 'Экскурсовод',
      data: TGData,
    },
    5: {
      inner_id: 4,
      title: 'Специалист по организации и предоставлению туристских услуг',
      data: TSAData,
    },
  }

  const PROF_BACK_ID = {...ADD_PROF_BACK_ID, ...MAIN_PROF_BACK_ID};

  return (
    <>
      <BlockWrapper
        title="Победители"
        className={styles.expertJuryWrap}
        contentClassName={styles.content}
        childClassName={styles.childs}
      >
        <div className={styles.block}>
          <h3>Основная группа профессий</h3>
          <div className={styles.profGrid}>
            {professions?.filter(item => item.is_active && MAIN_PROF_BACK_ID[item.id]).map((item, id) => 
              <div
                key={`prof_${id}`}
                className={cx(
                  styles.profItem,
                  styles[`item_${MAIN_PROF_BACK_ID[item.id].inner_id}`],
                  {[styles.selected]: profBackId === (item.id)}
                )}
                onClick={() => setProfBackId(item.id)}
                >
                {item.title}
              </div>
            )}
          </div>
        </div>

        <div className={styles.block}>
          <h3>Отраслевая группа профессий</h3>
          <div className={styles.profGrid}>
            {professions?.filter(item => item.is_active && ADD_PROF_BACK_ID[item.id]).map((item, id) => 
              <div
                key={`prof_${id}`}
                className={cx(
                  styles.profItem,
                  styles[`item_${ADD_PROF_BACK_ID[item.id].inner_id}`],
                  {[styles.selected]: profBackId === (item.id)}
                )}
                onClick={() => setProfBackId(item.id)}
                >
                {item.title}
              </div>
            )}
          </div>
        </div>
      </BlockWrapper>

      {profBackId>0 && 
        <div className={styles.resultsModal}>
          {HAData &&
            <div className={styles.modalView}>
              <div
                className={styles.close}
                onClick={() => setProfBackId(0)}
              />
              <h3>Победители в номинации «{PROF_BACK_ID[profBackId].title}»</h3>
              <div className={styles.resGrid}>
                {FiltWinners(PROF_BACK_ID[profBackId].data).map(winner => (
                  <div className={styles.resItem}>
                    {/* <div className={styles.image}> */}
                      <img className={styles.img} src={winner.photo} alt=''/>
                    {/* </div> */}
                    <span>{winner.full_name}</span>
                  </div>
                ))}
              </div>
            </div>
          }
        </div>
      }
    </>
  )
}

export default FinalsBlock;
