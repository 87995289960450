// author: Victor K.
import {
  Copyright,
  CFSExtLink,
} from '../../components';
import { BlockWrapper, Contacts } from '../../containers';
import styles from './styles.module.scss';

const Footer = () => (
  <>
    <Contacts />
    <BlockWrapper
      noTitle
      className={styles.copyWrap}
      contentClassName={styles.content}
      childClassName={styles.childs}
    >
      <Copyright />
      <CFSExtLink />
    </BlockWrapper>
  </>
)

export default Footer;