// author: Victor K.
import { TSocial } from '../types';

export const PRESSMAIL:string = 'presstourism@mos.ru';

export const SOCIALS:TSocial[] = [
  {
    name: 'vk',
    link: '//vk.com/',
    image: 'vk',
    active: false,
  }
]
