import { TStringsObj } from '../../types';
const defaultText = 'Введите текст';

export const InputTitle:TStringsObj = {
  secret_code: 'Код пройденного теста',
  full_name: 'Фамилия Имя Отчество',
  date_of_birth: 'Дата рождения',
  home_address: 'Домашний адрес',
  phone: 'Рабочий или мобильный телефон',
  email: 'Email',
  work_address: 'Место работы и занимаемая должность',
  work_experience: 'Общий стаж работы',
  admin_experience: 'Стаж работы по специальности «Администратор гостиницы», в т.ч. с указанием гостиницы и периода работы',
  work_union: 'Участие в профсоюзной организации',
  education: 'Сведение об образовании и повышении квалификации',
  additional_education: 'Дополнительное образование',
  // foreign_languages: 'Владение иностранными языками, уровень их знания (использование в работе)',
  foreign_languages: 'Владение иностранными языками с указанием уровня владения и практикой применения в работе (при наличии)',
  route_name: 'Название пешеходной и/или автобусной экскурсии, тема',
  route: `Маршрут пешеходной и/или автобусной экскурсии с 
          обязательным указанием места начала и завершения экскурсии`,
  objects_during_route: 'Объекты показа на маршруте (краткое перечисление)',
  duration: 'Продолжительность экскурсии (не более полутора часов)',
  route_km: 'Протяженность в км (не более 2 км)',
  short_description: 'Краткое описание/анонс экскурсии (не более 100 знаков)',
  amount_of_tours: 'Количество разработанных туристических продуктов за время работы в организации',
  presentation_link: `Презентация с анонсированием программы туристического продукта
                      (не более 15 слайдов) (ссылка на презентацию)`,
  tour_program_ru: `Концепция и программа туристического продукта
                    для российского туриста (ссылка на файл)`,
  tour_program_en: `Концепция и программа туристического продукта, включая план продвижения,
                    для иностранного туриста (ссылка на файл)`,
  video_fragment: `Видеоролик с анонсированием экскурсии 
                  на иностранном языке 
                  (длительность ролика не более 1 минуты)`,
  video_trailer: `Видеоролик с фрагментом проведенной 
                  экскурсии на иностранном языке
                  (длительность ролика – не более 3х минут)`,
  essay: `Короткое эссе (не более 600 символов) на тему «Ваши личные секреты гостеприимства 
          и предложения по улучшению качества обслуживания гостей в гостиницах Москвы»`,
  photo: 'Фотография заявителя',
  approval_first: 'Подтверждаю свое согласие на обработку персональных данных',
};

export const Placeholders:TStringsObj = {
  // default: 'Введите текст',
  secret_code: 'Введите код',
  full_name: 'Фамилия Имя Отчество',
  date_of_birth: 'ДД.ГГ.ММММ',
  home_address: 'Город, улица, дом, квартира',
  phone: '+7__________',
  email: 'example@email.com',
  work_address: defaultText,
  work_experience: defaultText,
  admin_experience: defaultText,
  work_union: 'Если состоите, то укажите название организации',
  education: defaultText,
  additional_education: defaultText,
  foreign_languages: defaultText,
  route_name: defaultText,
  route: defaultText,
  objects_during_route: defaultText,
  duration: defaultText,
  route_km: defaultText,
  short_description: defaultText,
  amount_of_tours: defaultText,
  presentation_link: defaultText,
  tour_program_ru: defaultText,
  tour_program_en: defaultText,
  video_trailer: 'Укажите ссылку на видеоматериал',
  video_fragment: 'Укажите ссылку на видеоматериал',
  essay: defaultText,
  
  photo: defaultText,
};

export const InputErrors:TStringsObj = {
  default: 'Заполните поле',
  phone: 'Введите номер телефона',
  email: 'Введите адрес почты',
  video_trailer: 'Укажите ссылку на видеоматериал',
  date_of_birth: 'Введите дату рождения',
  video_fragment: 'Укажите ссылку на видеоматериал',
  photo: 'Прикрепите фото',
};
