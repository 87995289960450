// author: Victor K.
import { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  title?: string,
  onClick?: () => void,
};

const MenuLink: FC<Props> = ({
  className,
  title = 'ссылка',
  onClick = () => {},
}) => <span
  className={cx(styles.link, className)}
  onClick={onClick}
>
  {title}
</span>

export default MenuLink;