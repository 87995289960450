// author: Victor K.
import { FC } from 'react';
import { BlockWrapper } from '../../containers';
import { HTMLContent, NoData } from '../../components';
import { TEvent } from '../../types';
// import { AboutData } from '../../_testData';
import styles from './styles.module.scss';

type Props = {
  data?: TEvent,
}

const AboutBlock: FC<Props> = ({
  data,
}) => (
  <BlockWrapper
    title='О конкурсе'
    className={styles.aboutWrap}
    contentClassName={styles.content}
    childClassName={styles.childs}
  >
    <NoData
      white
      count={data?.description.length}
    >
      <HTMLContent
        className={styles.HTMLText}
        content={data?.description || ''}
      />
    </NoData>
  </BlockWrapper>
)

export default AboutBlock;