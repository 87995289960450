// author: Victor K.
import { FC } from 'react';
import {
  ITranslatorAppl,
  TStringsObj,
} from '../../types';
import {
  Input,
  Textarea,
} from '../../components';
import { Placeholders, InputTitle } from './script';
import styles from './styles.module.scss';

type Props = {
  onChange?: (e:any) => void,
  formData: ITranslatorAppl,
  formErrors: TStringsObj,
  formTitle?: string,
}

const localInputTitle = {
  secret_code: InputTitle.secret_code,
  full_name: InputTitle.full_name,
  date_of_birth: InputTitle.date_of_birth,
  home_address: InputTitle.home_address,
  phone: InputTitle.phone,
  email: InputTitle.email,
  work_experience: InputTitle.work_experience,
  education: InputTitle.education,
  foreign_languages: InputTitle.foreign_languages,
  route_name: InputTitle.route_name,
  route: InputTitle.route,
  objects_during_route: InputTitle.objects_during_route,
  duration: InputTitle.duration,
  route_km: InputTitle.route_km,
  short_description: InputTitle.short_description,
  video_fragment: InputTitle.video_fragment,
  video_trailer: InputTitle.video_trailer,
};

const Translator: FC<Props> = ({
  onChange,
  formData,
  formErrors,
  formTitle,
}) => {
  return (
    <div>
      {formTitle && formTitle.concat(` - ITranslatorAppl`)}

      <div className={styles.test}>
        Перед заполнением анкеты Вам необходимо пройти тест.
        После прохождения Вам будет предоставлен код, который необходимо вставить в поле ниже 
        <div className={styles.testLink}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.google.com/forms/d/1agcOq3dNTyZj0z0crmypWZZT8RGQuKWPh1MKukUDXyI/edit?usp=sharing"
          >Ссылка на тест</a>
        </div>
        <Input
          name='secret_code'
          value={formData.secret_code}
          title={localInputTitle.secret_code}
          onChange={onChange}
          maxLength={120}
          placeholder={Placeholders.secret_code}
          error={formErrors?.secret_code}
        />
      </div>

      <Input
        name='full_name'
        value={formData.full_name}
        title={localInputTitle.full_name}
        onChange={onChange}
        placeholder={Placeholders.full_name}
        error={formErrors?.full_name}
      />
      <Input
        type='date'
        name='date_of_birth'
        max={'2020-01-01'}
        value={formData.date_of_birth}
        title={localInputTitle.date_of_birth}
        onChange={onChange}
        placeholder={Placeholders.date_of_birth}
        error={formErrors?.date_of_birth}
      />
      <Input
        name='home_address'
        value={formData.home_address}
        title={localInputTitle.home_address}
        onChange={onChange}
        placeholder={Placeholders.home_address}
        error={formErrors?.home_address}
      />
      <Input
        name='phone'
        value={formData.phone}
        title={localInputTitle.phone}
        onChange={onChange}
        placeholder={Placeholders.phone}
        error={formErrors?.phone}
      />
      <Input
        name='email'
        value={formData.email}
        title={localInputTitle.email}
        onChange={onChange}
        placeholder={Placeholders.email}
        error={formErrors?.email}
      />
      <Input
        name='work_experience'
        value={formData.work_experience}
        title={localInputTitle.work_experience}
        onChange={onChange}
        placeholder={Placeholders.work_experience}
        error={formErrors?.work_experience}
      />
      <Input
        name='education'
        value={formData.education}
        title={localInputTitle.education}
        onChange={onChange}
        placeholder={Placeholders.education}
        error={formErrors?.education}
      /> 
      <Textarea
        name='foreign_languages'
        value={formData.foreign_languages}
        title={localInputTitle.foreign_languages}
        onChange={onChange}
        placeholder={Placeholders.foreign_languages}
        error={formErrors?.foreign_languages}
      />
      <Input
        name='route_name'
        value={formData.route_name}
        title={localInputTitle.route_name}
        onChange={onChange}
        maxLength={510}
        placeholder={Placeholders.route_name}
        error={formErrors?.route_name}
      />
      <Input
        name='route'
        value={formData.route}
        title={localInputTitle.route}
        onChange={onChange}
        placeholder={Placeholders.route}
        error={formErrors?.route}
      />
      <Textarea
        name='objects_during_route'
        value={formData.objects_during_route}
        title={localInputTitle.objects_during_route}
        onChange={onChange}
        placeholder={Placeholders.objects_during_route}
        error={formErrors?.objects_during_route}
      />
      <Input
        name='duration'
        value={formData.duration}
        title={localInputTitle.duration}
        onChange={onChange}
        maxLength={255}
        placeholder={Placeholders.duration}
        error={formErrors?.duration}
      />
      <Input
        name='route_km'
        value={formData.route_km}
        title={localInputTitle.route_km}
        onChange={onChange}
        maxLength={510}
        placeholder={Placeholders.route_km}
        error={formErrors?.route_km}
      />
      <Textarea
        name='short_description'
        value={formData.short_description}
        title={localInputTitle.short_description}
        onChange={onChange}
        maxLength={100}
        placeholder={Placeholders.short_description}
        error={formErrors?.short_description}
      />
      <Input
        name='video_fragment'
        value={formData.video_fragment}
        title={localInputTitle.video_fragment}
        onChange={onChange}
        placeholder={Placeholders.video_fragment}
        error={formErrors?.video_fragment}
      />
      <Input
        name='video_trailer'
        value={formData.video_trailer}
        title={localInputTitle.video_trailer}
        onChange={onChange}
        placeholder={Placeholders.video_trailer}
        error={formErrors?.video_trailer}
      />
    </div>
  )
}

export default Translator;




