// author: Victor K.
export const URL = {
  'ROOT': '/', 
  'APPLICATION': {
    'GET': '/api/masters/app-count/',
    // 'GET': '/api/general_routes/applications/',
    'GET_ID': `/api/masters/applications/`, //{$id}/
    'POST': '/api/masters/applications/',
    'HOTEL_ADMIN': {
      'GET': '/api/masters/hotel-admin-application/', //{$id}/
      'POST': '/api/masters/hotel-admin-application/',
    },
    'TOUR_GUIDE': {
      'GET': '/api/masters/tour-guide-application/', //{$id}/
      'POST': '/api/masters/tour-guide-application/',
    },
    'TOUR_SPEC': {
      'GET': '/api/masters/tour-spec-application/', //{$id}/
      'POST': '/api/masters/tour-spec-application/',
    },
    'TRANSLATOR': {
      'GET': '/api/masters/translator-application/', //{$id}/
      'POST': '/api/masters/translator-application/',
    },
  },
  'PROFESSION': {
    'GET': '/api/masters/profession/',
  },
  'EVENT': {
    'GET': '/api/masters/events/',
  },
  'EXPERTS': {
    'GET': '/api/masters/expert/',
    'CAT': '/api/masters/expert-category/',
    'TYPE': '/api/masters/expert-type/',
  },
  'PARTNERS': {
    'GET': '/api/general_routes/partners/',
  },
  'DOCUMENTS': {
    'GET': '/api/general_routes/documents/',
  },
  'SITES': {
    'GET': '/api/masters/sites/',
  },
};