// author: Victor K.
import { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  contentClassName?: string,
  titleClassName?: string,
  childClassName?: string,
  title?: string,
  inlineFlow?: boolean,
  noTitle?: boolean,
};

const BlockWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  className,
  contentClassName,
  titleClassName,
  childClassName,
  title = 'Заголовок блока',
  inlineFlow = false,
  noTitle = false,
}) => (
  <div className={cx(styles.blockWrap, className)}>
    <div className={(cx(
      contentClassName,
      styles.blockContent,
      {
        [styles.inlineFlow]: inlineFlow,
      },
    ))}>
      {!noTitle && (<h2 className={cx(styles.blockTitle, titleClassName, 'linkedTitle')}>{title}</h2>)}
      <div className={cx(styles.blockChilds, childClassName)}>{children}</div>
    </div>
  </div>
);

export default BlockWrapper;
