// author: Victor K.
import { FC } from 'react';
import { TExpert } from '../../types';
// import noImg from '../../assets/images/no-img.png';
import styles from './styles.module.scss';

type Props = {
  item: TExpert,
  showLogo?: boolean,
};

const ExpertItem: FC<Props> = ({
  item,
  showLogo = false,
}) => (
  <div className={styles.expertItem}>
    <div className={styles.expertItem_name}>{item.full_name}</div>
    <div className={styles.expertItem_pos}>{item.position}</div>
  </div>
);

export default ExpertItem;