// author: Victor K.
import { FC } from 'react';
import styles from './styles.module.scss';

type Props = {};

const CFSExtLink: FC<Props> = () => {
  return (
    <div className={styles.cfsAuthor}>
      <span>Проект </span>
      <a
        className={styles.cfsExtLink}
        title='cyberform'
        target="_blank"
        rel="noreferrer"
        href='https://cyberform.webflow.io/'
      >CYBERFORM.SYSTEMS</a>
    </div>
  )
}

export default CFSExtLink;

