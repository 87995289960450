// author: Victor K.
import { FC } from 'react';
import { ReactComponent as Logo } from '../../assets/images/site-logo.svg';
import { ReactComponent as LogoMob } from '../../assets/images/site-logo-mobile.svg';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  white?: boolean,
};

const SiteTextLogo: FC<Props> = ({
  className,
  white = false,
}) => 
  <div className={cx({[styles.invert]: white}, className, styles.siteLogoWrap)}>
    <Logo className={cx(styles.logo, styles.logoDesk)} />
    <LogoMob className={cx(styles.logo, styles.logoMobile)} />
  </div>
;

export default SiteTextLogo;