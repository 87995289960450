// author: Victor K.
export const siteConfig:{
  siteId: number,
  useTestData: boolean,
  showApiErrs: boolean,
} = {
  siteId: 2,
  useTestData: true,
  showApiErrs: true,
}

