// author: Victor K.
import { FC } from 'react';
import { TPartner } from '../../types';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  item: TPartner,
  invert?: boolean,
};

const Partner: FC<Props> = ({
  className,
  item,
  invert = false,
}) => (
  <a
    className={cx(styles.partner, className)}
    href={item.url}
    target='_blank'
    rel="noreferrer"
    title={item.title}
  >
    <img
      className={styles.partner_image}
      src={!invert ? item.image : item.b_image || item.image}
      alt={item.title}
      height='60'
      width='auto'
    />
  </a>
);

export default Partner;