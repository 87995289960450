// author: Victor K.
import { FC } from 'react';
import { PRESSMAIL } from '../../appConstants';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
}

const ContactEmail: FC<Props> = ({
  className,
}) => <a
  className={cx(className, styles.contactEmail)}
  href={`mailto:${PRESSMAIL}`}
  title='Связаться с нами'
>
  {PRESSMAIL}
</a>

export default ContactEmail;