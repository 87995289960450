import axios from 'axios';

export const isLocalTest:boolean = window.location.href.includes('http://localhost');
const prodAdressForTest:string = 'https://master.moscow.guide/';
// const prodAdressForTest:string = 'https://moscow.g.uide/';
// const prodAdressForTest:string = 'https://randomuser.me/';

export const envSwitch: () => string = () => {
  return (isLocalTest ? prodAdressForTest : process.env.REACT_APP_API_URL) as string;
};

export const apiClient = axios.create({
  baseURL: envSwitch(),
});