// author: Victor K.
import { FC } from 'react';
// import { THtmlContent } from '../../types';
import cx from 'classnames';
import styles from './styles.module.scss';

interface Props {
  count?: number,
  white?: boolean,
};

const NoData: FC<Props> = ({
  count = 0,
  white = false,
  children,
}) => count > 0 ? (
  <>{children}</>
) : (
  <div className={cx(
    styles.loader,
    {[styles.white]: white},
  )}/>
);

export default NoData;