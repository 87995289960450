// author: Victor K.
import { FC, useRef, ChangeEvent } from 'react';
// import { Placeholders } from '../../appConstants';
import { TextField } from '../../components';
import styles from './styles.module.scss';

type Props = {
  name?: string,
  value?: string,
  placeholder?: string,
  title?: string,
  error?: string,
  onChange?: (e:any) => void,
};

const InputFile: FC<Props> = ({
  name = 'default',
  value,
  placeholder,
  title,  
  error,
  onChange,
}) => {
  const inputEl = useRef(null);
  
  const onChangeLocal = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    // eslint-disable-next-line
    const Files:File = (inputEl as any).current.files[0];
    if (onChange) onChange({file:Files, name:name});
  }

  return (
    <TextField
      value={value}
      error={error}
      title={title}
    >
      <input
        className={styles.input}
        type="file"
        name={name}
        // value={value}
        ref={inputEl}
        required
        accept=".png,.jpg,.jpeg"
        onChange={(e) => onChangeLocal(e)}
      />
    </TextField>
  )
}

export default InputFile;