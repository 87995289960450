// author: Victor K.
import { FC, PropsWithChildren, useState, useEffect } from 'react';
import { CubicButton } from '../../components';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  bgClassName?: string,
  invert?: boolean,
  accent?: boolean,
  withArrow?: boolean,
  autoClose?: boolean,
  openerTitle?: string,
  title?: string,
  submitTitle?: string,
  onSubmit?: () => void,
  onOpen?: () => void,
  onClose?: () => void,
};

const Form: FC<PropsWithChildren<Props>> = ({
  invert = false,
  accent = false,
  withArrow = false,
  autoClose = false,
  openerTitle = 'Открыть форму',
  title = "Форма",
  submitTitle = "Отправить",
  className,
  bgClassName,
  onSubmit,
  onOpen,
  onClose,
  children,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  
  const openHandler = () => {
    setOpen(true);
    if (onOpen) onOpen();
  };
  
  const closeHandler = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  const submitHandler = () => {
    onSubmit ? onSubmit() : setOpen(false);
  };

  useEffect(()=> {
    if (autoClose) setOpen(false);
  },[ autoClose ]);

  return (
    <>
      <CubicButton
        invert={invert}
        accent={accent}
        title={openerTitle}
        onClick={openHandler}
      />
      {isOpen && 
        <div className={styles.formWrapper}>
          <div
            className={cx(bgClassName, styles.formOverlay)}
            // onClick={closeHandler}
          />
          <div className={cx(className, styles.formContent)}>
            <div
              className={styles.formCloseCross}
              onClick={closeHandler}
            />
            {title && <h2 className={styles.formTitle}>{title}</h2>}
            {children}
            <CubicButton
              invert
              withArrow={withArrow}
              onClick={submitHandler}
              className={styles.fullWidth}
              title={submitTitle}
            />
          </div>
        </div>
      }
    </>
  )
}

export default Form;