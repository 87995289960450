// author: Victor K.
import { FC, useEffect } from 'react';
import { useRequest } from '../../hooks';
import { URL } from '../../appConstants';
import { IdCheck } from '../../helpers';
import { ISiteDocsReq, TSiteDoc } from '../../types';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  itemClassName?: string,
}

const ExternalDocs: FC<Props> = ({
  className,
  itemClassName,
}) => {
  const { data: Files, request: FilesReq } = useRequest<ISiteDocsReq>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => FilesReq({ url: URL.DOCUMENTS.GET }), []);

  const filterBySiteId = (Array:TSiteDoc[]) => {
    return Array?.filter(item => !!(item.site?.find(sId => IdCheck(sId))) && item.is_active)
  }

  function ExternalLink(props:{
    link: string,
    title: string,
  }) {
    const { link, title } = props;
    return <a
      className={cx(itemClassName, styles.extFile)}
      target="_blank"
      rel="noreferrer"
      href={link}
    >{title}</a>
  }

  return <div className={className}>
    {Files && filterBySiteId(Files.results).map((file, id) => <ExternalLink
      key={`file_${id}`}
      link={file.file}
      title={file.title}
    />)}
  </div>
}

export default ExternalDocs;