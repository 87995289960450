// author: Victor K.
import { FC } from 'react';
import {
  IHotelAdminAppl,
  TStringsObj,
} from '../../types';
import {
  Input,
  Textarea,
} from '../../components';
import { Placeholders, InputTitle } from './script';

type Props = {
  onChange?: (e:any) => void,
  formData: IHotelAdminAppl, 
  formErrors: TStringsObj,
  formTitle?: string,
}

const localInputTitle = {
  full_name: InputTitle.full_name,
  date_of_birth: InputTitle.date_of_birth,
  home_address: InputTitle.home_address,
  phone: InputTitle.phone,
  email: InputTitle.email,
  work_experience: 'Общий стаж работы в гостиничной отрасли',
  admin_experience: InputTitle.admin_experience,
  work_address: 'Место работы (название гостиницы, должность)',
  work_union: InputTitle.work_union,
  education: 'Сведения об образовании и повышении квалификации (учебное заведение, период обучения, специальность)',
  additional_education: 'Сведения о пройденных образовательных курсах за последние 5 лет (учебное заведение, специальность и компетенции)',
  foreign_languages: 'Владение иностранными языками, уровень их знания (использование в работе)',
  video_trailer: `Видеопрезентация участника на тему «Ваш искренний сервис»: какие корпоративные и личные принципы, 
                  ценности гостеприимства вы применяете в своей работе? (длительность ролика – не более 3х минут,
                  ролик должен быть снят на телефон или планшет)`,
  essay: InputTitle.essay,
};

const HotelAdmin: FC<Props> = ({
  onChange,
  formData,
  formErrors,
  formTitle,
}) => {
  return (
    <div>
      {formTitle && formTitle.concat(` - IHotelAdminAppl`)}

      <Input
        name='full_name'
        value={formData.full_name}
        title={localInputTitle.full_name}
        onChange={onChange}
        placeholder={Placeholders.full_name}
        error={formErrors?.full_name}
      />
      <Input
        type='date'
        name='date_of_birth'
        max={'2020-01-01'}
        value={formData.date_of_birth}
        title={localInputTitle.date_of_birth}
        onChange={onChange}
        placeholder={Placeholders.date_of_birth}
        error={formErrors?.date_of_birth}
      />
      <Input
        name='home_address'
        value={formData.home_address}
        title={localInputTitle.home_address}
        onChange={onChange}
        placeholder={Placeholders.home_address}
        error={formErrors?.home_address}
      />
      <Input
        name='phone'
        value={formData.phone}
        title={localInputTitle.phone}
        onChange={onChange}
        placeholder={Placeholders.phone}
        error={formErrors?.phone}
      />
      <Input
        name='email'
        value={formData.email}
        title={localInputTitle.email}
        onChange={onChange}
        placeholder={Placeholders.email}
        error={formErrors?.email}
      />
      <Input
        name='work_experience'
        value={formData.work_experience}
        title={localInputTitle.work_experience}
        onChange={onChange}
        placeholder={Placeholders.work_experience}
        error={formErrors?.work_experience}
      />
      <Textarea
        name='admin_experience'
        value={formData.admin_experience}
        title={localInputTitle.admin_experience}
        onChange={onChange}
        maxLength={510}
        placeholder={Placeholders.admin_experience}
        error={formErrors?.admin_experience}
      />
      <Input
        name='work_address'
        value={formData.work_address}
        title={localInputTitle.work_address}
        onChange={onChange}
        placeholder={Placeholders.work_address}
        error={formErrors?.work_address}
        />
      <Input
        name='work_union'
        value={formData.work_union}
        title={localInputTitle.work_union}
        onChange={onChange}
        maxLength={510}
        placeholder={Placeholders.work_union}
        error={formErrors?.work_union}
      />
      <Input
        name='education'
        value={formData.education}
        title={localInputTitle.education}
        onChange={onChange}
        placeholder={Placeholders.education}
        error={formErrors?.education}
      />
      <Input
        name='additional_education'
        value={formData.additional_education}
        title={localInputTitle.additional_education}
        onChange={onChange}
        placeholder={Placeholders.additional_education}
        error={formErrors?.additional_education}
      />
      <Textarea
        name='foreign_languages'
        value={formData.foreign_languages}
        title={localInputTitle.foreign_languages}
        onChange={onChange}
        placeholder={Placeholders.foreign_languages}
        error={formErrors?.foreign_languages}
      />
      <Input
        name='video_trailer'
        value={formData.video_trailer}
        title={localInputTitle.video_trailer}
        onChange={onChange}
        placeholder={Placeholders.video_trailer}
        error={formErrors?.video_trailer}
      />
      <Textarea
        name='essay'
        maxLength={600}
        value={formData.essay}
        title={localInputTitle.essay}
        onChange={onChange}
        placeholder={Placeholders.essay}
        error={formErrors?.essay}
      />
    </div>
  )
}

export default HotelAdmin;