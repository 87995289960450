// author: Victor K.
import { FC, useEffect, useState } from 'react';
import {
  TExpert,
  // TExpertCat,
  TExpertType,
  IExpertCatReq,
  IExpertTypeReq,
} from '../../types';
import { useRequest } from '../../hooks';
import { URL } from '../../appConstants';
import { BlockWrapper } from '../';
import { ExpertItem, HTMLContent } from '../../components';
import {
  ExpertData,
  // ExpertCatData,
  // ExpertTypeData,
} from '../../_testData';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  data?: TExpert[],
}

const ExpertBlock: FC<Props> = ({
  data,
}) => {
  const [switchId, setSwitchId] = useState<number>(1);

  const { data: ExpertCat, request: ExpertCatReq } = useRequest<IExpertCatReq>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => ExpertCatReq({ url: URL.EXPERTS.CAT }), []);
  
  const { data: ExpertType, request: ExpertTypeReq } = useRequest<IExpertTypeReq>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => ExpertTypeReq({ url: URL.EXPERTS.TYPE }), []);

  const FilteredData = (type:number) =>
    (data?.length ? data : ExpertData).filter(expert =>
      expert.category_of_professions === switchId && 
      expert.type_of_expert === type &&
      expert.is_active
    );

  function ExpertsByType(props:{
    type:TExpertType,
  }) {
    const { type } = props;
    const [isOpen, setOpen] = useState<boolean>(false);

    return FilteredData(type.id).length > 0 ? (
      <div
        className={styles.expertCat}
      >
        <div
          className={cx(styles.title, {[styles.opened]: isOpen})}
          onClick={() => setOpen(!isOpen)}
        >
          {type.title}
        </div>
        {isOpen && <div className={styles.grid}>
          {FilteredData(type.id).map((expert) =>
            <ExpertItem 
              key={expert.id}
              item={expert}
            />
          )}
        </div>}
      </div>
    ) : <></>;
  };

  return (
    <BlockWrapper
      title="Организационный комитет"
      className={styles.expertJuryWrap}
      contentClassName={styles.content}
      childClassName={styles.childs}
    >
      <div className={styles.switcher}>
        {/* {ExpertCatData.map((item, id) =>  */}
        {ExpertCat?.results.filter(item => item.is_active).map((item, id) => 
          <div
            key={`switch_${id}`}
            className={cx(styles.switch,
              {[styles.active]: id+1 === switchId},
            )}
            onClick={() => setSwitchId((id+1))}
          >
            <HTMLContent content={item.title} />
          </div>
        )}
      </div>

      {ExpertType?.results.filter(item => item.is_active).map((type) => 
        <ExpertsByType
          key={type.id}
          type={type}
        />
      )}
    </BlockWrapper>
  )
}

export default ExpertBlock;
