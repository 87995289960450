// author: Victor K.
import { FC } from 'react';
import { THtmlContent } from '../../types';
import cx from 'classnames';
import styles from './styles.module.scss';

interface Props extends THtmlContent {
  className?: string,
};

const HTMLContent: FC<Props> = ({
  className,
  content,
}) => (
  <div
    className={cx(styles.HTMLContent, className)}
    dangerouslySetInnerHTML={{ __html: content }}
  />
);

export default HTMLContent;