// author: Victor K.
import { FC } from 'react';
import cx from 'classnames';
import { SOCIALS as SOCIAL_LINKS } from '../../appConstants';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};


const Socials: FC<Props> = ({
  className,
}) => {
  return (<div className={cx(styles.links, className)}>
      {SOCIAL_LINKS?.filter(i => i.active).map((item, id) => 
        <a
          className={cx(
            styles.socialLink,
            styles[item.name],
          )}
          key={`${className}_${id}`}
          title={item.name}
          target="_blank"
          rel="noreferrer"
          href={item.link}
        >X
        </a>
      )}
    </div>
  )
};

export default Socials;