// author: Victor K.
import { FC } from 'react';
import { Placeholders } from '../../appConstants';
import { TextField } from '../../components';
import styles from './styles.module.scss';

type Props = {
  type?: 'text' | 'phone' | 'email' | 'date' | 'file',
  name?: string,
  value?: string,
  placeholder?: string,
  title?: string,
  error?: string,
  maxLength?: number,
  max?: string,
  min?: number,
  onChange?: (e:any) => void,
};

const d:{[index:string]: string} = {
  text: 'text',
  phone: 'phone',
  email: 'email',
}

const Input: FC<Props> = ({
  type = 'text',
  name = 'default',
  value,
  placeholder,
  title,
  error,
  maxLength = 1000,
  max,
  min = 1,
  onChange,
}) => {
  const onChangeLocal = (e:any) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      let val = value as string;
      if (val.indexOf('+7') === 0) val = val.substring(2,12).replace(/[^0-9]/g,"");
      if (val.indexOf('+') === 0) val = val.substring(1,12).replace(/[^0-9]/g,"");
      e.target.value = `+7${val}`;
    }
    if (name === 'email') {
      const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,4})$/;
      let val = value as string;
      if(reg.test(val)) {
        e.target.value = val;
      }
    }
    if (onChange) onChange(e);
  }

  return (
    <TextField
      value={value}
      error={error}
      title={title}
    >
      <input
        className={styles.input}
        type={d[name] || type}
        name={name}
        value={value}
        max={max}
        maxLength={maxLength}
        min={min}
        required
        placeholder={placeholder || Placeholders[name] || Placeholders[type]}
        onChange={(e) => onChangeLocal(e)}
      />
    </TextField>
  )
}

export default Input;
