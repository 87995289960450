// author: Victor K.
import { siteConfig } from '../appConstants';

export const IdCheck:(id:number)=>boolean = (id) => id===siteConfig.siteId;

type Data = {
  site?: number[];
  is_active?: boolean,
}

export const filterBySiteId = (Array:Data[]) => {
  return Array?.filter(item => !!(item.site?.find(sId => IdCheck(sId))) && item.is_active)
}