// author: Victor K.
import {
  Socials,
  ContactEmail,
  ExternalDocs,
  SiteTextLogo,
} from '../../components';
import { BlockWrapper } from '../../containers';
import styles from './styles.module.scss';

const Contacts = () => (
  <BlockWrapper
    className={styles.contactsWrap}
    contentClassName={styles.content}
    childClassName={styles.childs}
    title='Контакты'
    titleClassName={styles.hiddenTitle}
    // noTitle
  >
    <SiteTextLogo className={styles.bottomLogo} white />
    <div className={styles.right}>
      <ContactEmail className={styles.email} />
      <ExternalDocs itemClassName={styles.file} />
      <Socials className={styles.minLinks}/>
    </div>
  </BlockWrapper>
)

export default Contacts;