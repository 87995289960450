// author: Victor K.
import { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  value?: string,
  className?: string,
  title?: string,
  error?: string,
};

const TextField: FC<PropsWithChildren<Props>> = ({
  value,
  className,
  title,
  error,
  children,
}) => {
  return (
    <div
      className={cx(
        className,
        styles.textFieldWrap,
        {[styles.error]: error},
      )
    }>
      {title && <div className={styles.title}>
        {title}
      </div>}
      {children}
      {error && <span className={styles.errorText}>
        {error}
      </span>}
    </div>
  )
}

export default TextField;