// author: Victor K.
import { TPartner } from '../types';
import b_discover from '../assets/images/partners/logo-b-discover.svg';
import w_discover from '../assets/images/partners/logo-w-discover2.svg';
import w_mkpp from '../assets/images/partners/logo-w-mkpp.svg';
import b_mkpp from '../assets/images/partners/logo-b-mkpp.svg';
import b_prof from '../assets/images/partners/logo-b-prof.svg';
import w_prof from '../assets/images/partners/logo-w-prof.svg';
import b_russpass from '../assets/images/partners/logo-b-russpass.svg';
import w_russpass from '../assets/images/partners/logo-w-russpass.svg';
import b_tourism from '../assets/images/partners/logo-b-tourism.svg';

export const PartnersData:TPartner[] = [
  {
    id: 0,
    title: 'Discover Moscow',
    image: b_discover,
    b_image: w_discover,
    url: '//discovermoscow.com/',
    is_active: true,
    on_top: true,
  },{
    id: 1,
    title: 'RussPass',
    image: b_russpass,
    b_image: w_russpass,
    url: '//russpass.ru/',
    is_active: true,
    on_top: true,
  },{
    id: 2,
    title: '',
    image: b_tourism,
    url: '//mos.ru/tourism/',
    is_active: true,
  },{
    id: 3,
    title: 'МОСКОВСКИЙ ПРОФЕССИОНАЛЬНЫЙ СОЮЗ работников физической культуры, спорта и туризма',
    image: b_prof,
    b_image: w_prof,
    url: '//toursport.pro/',
    is_active: true,
    on_top: true,
  },{
    id: 4,
    title: 'Московская Конфедерация промышленников и предпринимателей (работодателей)',
    image: b_mkpp,
    b_image: w_mkpp,
    url: '//mkppr.ru/',
    is_active: true,
    on_top: true,
  },
];