// author: Victor K.
import { TStringsObj } from '../types';

export const InputErrors:TStringsObj = {
  text: 'Введите текст',
  phone: 'Введите номер телефона',
  email: 'Введите адрес почты',
  select: 'Выберите значение',
  checkbox: 'Выберите значение',
  radio: 'Выберите значение',
};

export const Placeholders:TStringsObj = {
  def: 'Введите значение',
  text: 'Введите текст',
  phone: 'Введите номер телефона',
  email: 'Введите адрес почты',
  select: 'Выберите значение',
  checkbox: 'Выберите значение',
  radio: 'Выберите значение',
};

