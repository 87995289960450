// author: Victor K.
import { FC, useState, useEffect } from 'react';
import { TimeHelper, secBefore, moreOneDay } from '../../helpers';
import { TDigits } from '../../types';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  title?: string,
  finishDate: string,
  endingText?: string,
  setActive?: (b:boolean) => void,
};

const Timer: FC<Props> = ({
  title = 'До окончания подачи заявок',
  finishDate = '',
  endingText = 'Подача заявок закончена',
  setActive,
}) => {
  const [diff, setDiff] = useState<number>(0);
  useEffect(() => setDiff(secBefore(finishDate)), [ finishDate ]);

  useEffect(() => {
    let timer:any;
    if (diff >= 0 && !timer) {
      timer = setTimeout(() => {
        setDiff(secBefore(finishDate));
      }, moreOneDay(diff) ? 60000 : 1000);
    }
    setActive && setActive(diff > 0);
    return () => timer && clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ diff ]);


  function TimeValue(props:{
    digit:TDigits,
    separator: boolean,
  }) {
    const { digit, separator } = props;
    const { time, title } = TimeHelper(digit, diff, separator);

    return <>
      {separator && <div className={styles.timeValue}>:</div>}
      <div className={cx(styles.timeValue, styles.fixedWidth)}>
        {time}
        <span className={styles.timeName}>{title}</span>
      </div>
    </>;
  };

  return (diff > 0) ? (
    <div className={styles.timerWrapper}>
      <div className={styles.timerTitle}>{title}</div>
      <div className={styles.timer}>
        {(moreOneDay(diff) ? ['d','h','m'] : ['h','m','s']).map((digit, id) =>
          <TimeValue
            key={digit}
            digit={digit as TDigits}
            separator={id !== 0}
          />
        )}
      </div>
    </div>
  ) : (
    <div className={styles.timeEnded}>
      {endingText}
    </div>
  );
}

export default Timer;