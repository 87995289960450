// author: Victor K.
import { TDigits } from '../types';

export const secInDay:number = 86400;

export const moreOneDay:(secs?:number) => boolean = (secs: number = 0) => secs > secInDay;

// return seconds before 'end_date';
export const secBefore:((str:string) => number) = (str) => 
  Math.trunc((new Date(str).getTime() - new Date().getTime())/1000 + (19*60*60));

export const TimeHelper:(
  (dig: TDigits, time: number, zeroStart?: boolean) => {time: string, title: string}
) = (
  dig,
  time,
  zeroStart = false,
) => {
  const unit = {
    'd': ['День','Дня','Дней'],
    'h': ['Час','Часа','Часов'],
    'm': ['Минута','Минуты','Минут'],
    's': ['Секунда','Секунды','Секунд'],
  }, uVal = {
    'd': time/60/60/24,
    'h': time/60/60%24,
    'm': time/60%60,
    's': time%60,
  },
  d = Math.trunc(uVal[dig]);
  return {
    time: ((zeroStart && d<10) ? '0' : '')+d,
    title: unit[dig][(d%10 === 1 && d!==11) ? 0 : (d%10>1 && d%10<5 && (d<10 || d>20)) ? 1 : 2],
  };
}