// author: Victor K.
import { useState, useEffect } from 'react';
import { useRequest } from '../../hooks';
import { URL } from '../../appConstants';
import {
  IEventReq,
  IPartnerReq,
  IExpertReq,
  TProfessionsReq,
  TFinalsReq,
} from '../../types';
import {
  AboutBlock,
  EngageBlock,
  ExpertBlock,
  FinalsBlock,
  Footer,
  Header,
  PartnersBlock,
  TimetableBlock,
} from '../../containers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  toast.configure();
  const [active, setActive] = useState<boolean>(true);
  
  const { data: AppCount, request: AppCountReq } = useRequest<{app_count: number}>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => AppCountReq({ url: URL.APPLICATION.GET }), []);
  
  const { data: Event, request: EventReq } = useRequest<IEventReq>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => EventReq({ url: URL.EVENT.GET }), []);

  const { data: Professions, request: ProfReq } = useRequest<TProfessionsReq>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => ProfReq({ url: URL.PROFESSION.GET }), []);
  
  const { data: Experts, request: ExpReq } = useRequest<IExpertReq>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => ExpReq({ url: URL.EXPERTS.GET }), []);

  const { data: Partners, request: PartReq } = useRequest<IPartnerReq>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => PartReq({ url: URL.PARTNERS.GET }), []);
  

  const { data: HAData, request: HADataReq } = useRequest<TFinalsReq>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => HADataReq({ url: URL.APPLICATION.HOTEL_ADMIN.GET, params: {limit: 1000}}), []);
  
  const { data: TGData, request: TGDataReq } = useRequest<TFinalsReq>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => TGDataReq({ url: URL.APPLICATION.TOUR_GUIDE.GET, params: {limit: 1000}}), []);

  const { data: TSAData, request: TSADataReq } = useRequest<TFinalsReq>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => TSADataReq({ url: URL.APPLICATION.TOUR_SPEC.GET, params: {limit: 1000}}), []);

  const { data: TRData, request: TRDataReq } = useRequest<TFinalsReq>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => TRDataReq({ url: URL.APPLICATION.TRANSLATOR.GET, params: {limit: 1000}}), []);

  return (
    <div className="App">

      <Header
        count={AppCount?.app_count}
        active={active}
        Event={Event}
        Professions={Professions}
      />
      <EngageBlock
        time={Event?.results[0]}
        setActive={setActive}
      />
      <AboutBlock
        data={Event?.results[0]}
      />
      <TimetableBlock
        data={Event?.results[0]}
      />
      {/* <AttentionBlock
        active={active}
        Event={Event}
        Professions={Professions}
      /> */}
      <FinalsBlock
        professions={Professions?.results}
        HAData={HAData?.results}
        TGData={TGData?.results}
        TSAData={TSAData?.results}
        TRData={TRData?.results}
      />
      <ExpertBlock
        data={Experts?.results}
      />
      <PartnersBlock
        data={Partners?.results}
      />
      <Footer />
    </div>
  );
}

export default App;
