// author: Victor K.
import { FC, PropsWithChildren } from 'react';
import { Placeholders } from '../../appConstants';
import { TextField } from '../../components';
import styles from './styles.module.scss';

type Props = {
  type?: 'text' | 'phone' | 'email',
  name?: string,
  value?: string,
  placeholder?: string,
  title?: string,
  error?: string,
  maxLength?: number,
  onChange?: (e:any) => void,
};

const Textarea: FC<PropsWithChildren<Props>> = ({
  type = 'text',
  name = 'def',
  value,
  placeholder,
  title,
  error,
  maxLength = 1000,
  onChange,
}) => {
  const onChangeLocal = (e:any) => console.log(e.target.value)

  return (
    <TextField
      value={value}
      error={error}
      title={title}
    >
      <textarea
        className={styles.textarea}
        name={name}
        value={value}
        maxLength={maxLength}
        required
        placeholder={placeholder || Placeholders[name] || Placeholders[type]}
        onChange={(e) => onChange ? onChange(e) : onChangeLocal(e)}
      />
    </TextField>
  )
}

export default Textarea;