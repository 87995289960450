// author: Victor K.
import { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  text?: string,
  error?: any,
  name: string,
  value: boolean,
  onClick?: (name: string, val: boolean) => void,
}

const CheckboxSwitcher: FC<Props> = ({
  text = '--Условие соглашения--',
  error,
  name,
  value,
  onClick = () => {},
}) => {
  return (
    <div
      className={cx(
        styles.checkboxSwitcher,
        // {[styles.error]: error},
      )}
      onClick={() => onClick ? onClick(name, !value) : {}}
    >
      <div className={cx(
        styles.psCheckbox,
        {[styles.checked]: value},
        {[styles.error]: error},
      )} />
      <div className={styles.text}>
        {text}
      </div>
    </div>
  )
}

export default CheckboxSwitcher;