// author: Victor K.
import { FC, useEffect, useState} from 'react';
import { TPartner } from '../../types';
import { BlockWrapper } from '../../containers';
import { IdCheck } from '../../helpers';
import { Partner, NoData } from '../../components';
import styles from './styles.module.scss';

type Props = {
  setActive?: (f:boolean) => void,
  data?: TPartner[],
}

const PartnersBlock: FC<Props> = ({
  data,
}) => {
  const [Filtered, setFiltered] = useState<TPartner[]>([]);
  useEffect(() => {
    let d:TPartner[] = [];
    for (let i = 0; i < 10; i++) {
      data?.map((expert) => {
        if (expert?.priority === `${i}`) {
          d.push(expert);
          setFiltered(d);
        }
        return '';
      })
    }
  }, [data]);

  const filterBySiteId = (Array:TPartner[]) => {
    return Array?.filter(item => !!(item.site?.find(sId => IdCheck(sId))) && item.is_active)
  }

  return (
    <BlockWrapper
      title='Партнеры'
      className={styles.partnersWrap}
      contentClassName={styles.content}
      childClassName={styles.childs}
    >
      <NoData count={Filtered.length}>
        <div className={styles.grid}>
          {filterBySiteId(Filtered).map((partner) => partner.is_active &&
            <Partner
              key={partner.id}
              item={partner}
            />
          )}
        </div>
      </NoData>
    </BlockWrapper>
  )
}

export default PartnersBlock;