// author: Victor K.
import { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  title?: string,
  invert?: boolean,
  accent?: boolean,
  withArrow?: boolean,
  onClick?: () => void,
};

const CubicButton: FC<Props> = ({
  className,
  title = 'Кнопка',
  invert = false,
  accent = false,
  withArrow = false,
  onClick = () => {},
}) => (
  <div
    className={cx(
      className,
      styles.cubicButton,
      {
        [styles.invert]: invert,
        [styles.accent]: accent,
        [styles.withArrow]: withArrow,
      })}
    onClick={onClick}
  >
    {title}
  </div>
);

export default CubicButton;