// author: Victor K.
import { FC } from 'react';
import { TEvent } from '../../types';
import { BlockWrapper } from '../../containers';
import {
  Partner,
  Socials,
  NoData,
  SiteTextLogo,
  Timer,
} from '../../components';
import { PartnersData } from '../../_testData';
import styles from './styles.module.scss';

type Props = {
  setActive?: (b:boolean) => void,
  part?: TEvent,
  time?: TEvent,
}

const TAGS = [
  '#Московские мастера',
  '#Туризм',
  '#Экскурсовод',
  '#Гостиницы',
  '#Профессии',
]

const EngageBlock: FC<Props> = ({
  setActive,
  part,
  time,
}) => {
  return(<>
    <BlockWrapper
      noTitle
      className={styles.engageWrap}
      contentClassName={styles.content}
      childClassName={styles.childs}
    >
      <Timer
        finishDate={time?.end_appl || ''}
        setActive={setActive}
      />
      <div>
        <SiteTextLogo className={styles.bigLogo} />
        <div className={styles.tags}>
          {TAGS.map((tag, id) => 
            <div 
              key={`tag_${id}`}
              className={styles.tag}
            >
              {tag}
            </div>
          )}
        </div>
      </div>
    </BlockWrapper>
    <BlockWrapper
      className={styles.linksWrap}
      contentClassName={styles.content}
      childClassName={styles.childs}
      noTitle
    >
      <NoData count={PartnersData?.length}>
        <div className={styles.partners}>
          {PartnersData.filter(part => part.on_top && part.is_active).map(
            partner =>
              <Partner
                className={styles.partnerItm}
                key={partner.id}
                item={partner}
                invert
              />
            )
          }
        </div>
      </NoData>
      <Socials />
    </BlockWrapper>
  </>)
}

export default EngageBlock;